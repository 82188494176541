<template>
  <div>
    <ModalHistoriaAmbientesBecario />
    <modal-becario-ambiente-form @actualizarTabla="() => $refs.refTablaBecariosResidencia.refresh()" />
    <becarios-asignar-periodo-modal
      :servicio="servicio"
      @actualizarTabla="actualizarTabla"
    />
    <becarios-finalizar-retirar-beca-modal
      :servicio="servicio"
      @actualizarTabla="actualizarTabla"
      @onSubmitmModalFinzalizarRetirar="onSubmitmModalFinzalizarRetirar"
    />

    <b-card
      no-body
      class="mb-0 px-2 pt-1"
    >

      <b-table
        id="my-table"
        ref="refTablaBecariosResidencia"

        class="position-relative"
        show-empty
        hover
        small
        responsive
        :busy.sync="isBusy"
        :items="myProvider"
        :fields="fields"
        :current-page="currentPage"
        :empty-text="'No hay registros para mostrar'"
        :empty-filtered-text="'No hay registros que coincidan con su solicitud.'"
        :per-page="perPage"
        :filter="filter"
        :filter-included-fields="filterOn"
        :sort-by="sortBy"
        :sort-desc="sortDesc"
        :no-provider-filtering="true"
        selectable
        caption-top
        select-mode="multi"
        selected-variant="success"
        @row-selected="onRowSelected"
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle" />
            <strong>Cargando...</strong>
          </div>
        </template>

        <template #table-caption>
          <b-row>
            <b-col
              xl="6"
              lg="6"
              md="6"
              sm="12"
              xs="12"
            >
              <span>
                <b-button
                  size="sm"
                  variant="outline-secondary"
                  @click="selectAllRows"
                >
                  Seleccionar todos
                </b-button>
                <b-button
                  size="sm"
                  variant="outline-secondary"
                  @click="clearSelected"
                >
                  Limpiar selección
                </b-button>
                <b-dropdown
                  variant="outline-dark"
                  class="btn-icon rounded-circle p-0"
                  size="sm"
                  right
                >
                  <template #button-content>
                    Acción masiva
                    <!-- <feather-icon
                      icon="SettingsIcon"
                      size="16"
                      class="align-middle m-0 p-0"
                    /> -->
                  </template>

                  <b-dropdown-item @click="asignarPeriodo">
                    <feather-icon icon="CalendarIcon" />
                    <span class="align-middle ml-50">Asignar/Editar periodo beca</span>
                  </b-dropdown-item>
                  <!-- <b-dropdown-item @click="finalizarBeca">
                    <feather-icon icon="CalendarIcon" />
                    <span class="align-middle ml-50">Finalizar beca</span>
                  </b-dropdown-item> -->
                  <b-dropdown-item @click="retirarBeca">
                    <feather-icon
                      icon="MinusCircleIcon"
                      class="text-danger"
                    />
                    <span class="align-middle ml-50 text-danger">Retirar beca</span>
                  </b-dropdown-item>

                </b-dropdown>
              </span>
            </b-col>
            <b-col
              xl="6"
              lg="6"
              md="6"
              sm="12"
              xs="12"
              class="d-flex justify-content-end"
            >
              <div class="mr-1">
                <label>Mostrar</label>
                <b-form-select
                  v-model="perPage"
                  :options="pageOptions"
                  :clearable="false"
                  size="md"
                  style="width:auto"
                  class="per-page-selector d-inline-block mx-50"
                />
                <label>registros</label>
              </div>
              <div>

                <b-form-input
                  v-model="filter"
                  type="search"
                  placeholder="Buscar..."
                  size="md"
                />
              </div>
            </b-col>

          </b-row>
        </template>
        <!-- Example scoped slot for select state illustrative purposes -->
        <template #cell(selected)="{ rowSelected }">
          <template v-if="rowSelected">
            <span aria-hidden="true">
              <feather-icon
                icon="CheckIcon"
                size="30"
                class="text-danger"
              />
            </span>
            <span class="sr-only">Selected</span>
          </template>
          <template v-else>
            <span aria-hidden="true">&nbsp;</span>
            <span class="sr-only">Not selected</span>
          </template>
        </template>

        <template #cell(index)="data">
          {{ data.index + from }}
        </template>

        <!-- Column: User -->
        <template #cell(usuario)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :variant="`light-${resolveUserServicioVariant(data.item.persona.nombres)}`"
                :text="avatarText(`${data.item.persona.apellido_paterno} ${data.item.persona.apellido_materno}`)"
              />
            </template>
            <b-link
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.persona.nombre_completo }}
            </b-link>
            <small class="text-muted text-monospace py-0">DNI° {{ data.item.persona.numero_documento }} - CEL: {{ data.item.persona.celular ? data.item.persona.celular : '---------' }}</small>

          </b-media>
        </template>

        <!-- Column: Status -->
        <template #cell(estado)="data">
          <b-badge
            pill
            :variant="`light-${resolvetatusVariant(data.item.estado)}`"
            class="text-capitalize"
          > {{ data.item.estado }}
            <!-- {{ data.item.estado ? 'Activo' : 'Inactivo' }} -->
          </b-badge>
        </template>

        <template #cell(habitacion)="data">
          <!-- {{ data.item.habitaciones }} -->
          <span
            v-for="(h, index) in data.item.becario_habitacion"
            :key="index"
          >
            <b-table-simple
              v-if="h.estado"
              style="font-size: 0.8em;"
              small
            >
              <b-thead>
                <b-th>Cod.</b-th>
                <b-th>Ingreso.</b-th>
                <b-th>Salida.</b-th>
                <b-th>Mas</b-th>
              </b-thead>
              <b-tr>
                <b-td><b-badge
                  :variant="h.estado ? 'success' : ''"
                >
                  {{ h.habitacion.codigo }}
                </b-badge></b-td>
                <b-td>{{ new Intl.DateTimeFormat('es-PE',{month: 'numeric', day:'numeric', year:'numeric', hour:'numeric', minute: 'numeric'}).format(new Date(h.fecha_ingreso)) }}</b-td>
                <b-td>{{ new Intl.DateTimeFormat('es-PE',{month: 'numeric', day:'numeric', year:'numeric', hour:'numeric', minute: 'numeric'}).format(new Date(h.fecha_salida)) }}</b-td>
                <b-td><b-button
                  size="sm"
                  @click="verHabitaciones(data.item)"
                >
                  +
                </b-button></b-td>
              </b-tr>
            </b-table-simple>

          </span>

        </template>

        <template #cell(fecha_inicio)="row">
          <span v-if="row.item.periodo_actual.fecha_inicio">
            {{ new Intl.DateTimeFormat('es-PE',{month: 'numeric', day:'numeric', year:'numeric'}).format(new Date(`${row.item.periodo_actual.fecha_inicio}T00:00:00`)) }}
          </span>
        </template>

        <template #cell(fecha_fin_salida)="row">
          <span v-if="row.item.periodo_actual.fecha_fin">
            {{ new Intl.DateTimeFormat('es-PE',{month: 'numeric', day:'numeric', year:'numeric'}).format(new Date(`${row.item.periodo_actual.fecha_fin}T00:00:00`)) }}
          </span>
        </template>

        <!-- Column: Actions -->
        <template #cell(acciones)="data">
          <b-dropdown
            v-if="data.item.estado === 'activo'"
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <!-- Asignar habitacion -->
            <b-dropdown-item
              v-if="!data.item.becario_habitacion.some(val => val.estado === 1)"
              @click="asignarHabitacion(data.item)"
            >
              <feather-icon icon="HomeIcon" />
              <span class="align-middle ml-50">Asignar habitación</span>
            </b-dropdown-item>

            <!-- Desasignar beca -->
            <b-dropdown-item
              v-if="data.item.becario_habitacion.some(val => val.estado === 1)"
              v-b-modal.modal-2
              @click="showDesasignar(data.item)"
            >
              <feather-icon icon="UserXIcon" />
              <span class="align-middle ml-50">Desasignar habitación</span>
            </b-dropdown-item>

            <!-- <b-dropdown-item
              @click="deleteItem(data.item)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Quitar beca</span>
            </b-dropdown-item> -->
          </b-dropdown>
        </template>

      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Mostrando registros del {{ from }} al {{ to }} de un total de {{ totalRows }} registros</span>

          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>
        </b-row>
      </div>

    </b-card>
    <desasignar-habitacion-form-modal
      :item-habitacion="itemHabitacion"
      :item-persona="itemPersona"
      @update-table-residentes="actualizarTabla"
    />
  </div>
</template>

<script>
import {
  BCard, BTable, BSpinner, VBModal, BRow, BCol, BFormInput, BDropdown, BDropdownItem, BPagination, BBadge, BButton, BMedia,
  BAvatar,
  BFormSelect,
  BLink,
  BTr,
  BTableSimple,
  BTd,
  BTh,
  BThead,
} from 'bootstrap-vue'

// import vSelect from 'vue-select'
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import { avatarText } from '@core/utils/filter'
// import BuscarPersona from '@/views/salud/triaje/BuscarPersona.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import DesasignarHabitacionFormModal from '../components/DesasignarHabitacionFormModal.vue'
import becarioStoreModule from './becarioStoreModule'
// import TriajeServicioModal from './TriajeServicioModal.vue'
import useSaludUtilitarios from '../useSaludUtilitarios'
import BecariosAsignarPeriodoModal from './BecariosAsignarPeriodoModal.vue'
import BecariosFinalizarRetirarBecaModal from './BecariosFinalizarRetirarBecaModal.vue'
import ModalBecarioAmbienteForm from './ModalBecarioAmbienteForm.vue'
import ModalHistoriaAmbientesBecario from './ModalHistoriaAmbientesBecario.vue'

export default {
  components: {
    BTableSimple,
    BFormSelect,
    BTable,
    BBadge,
    BTr,
    BTd,
    BThead,
    BTh,
    // vSelect,
    BSpinner,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BDropdown,
    BDropdownItem,
    BPagination,
    BMedia,
    BAvatar,
    BLink,
    DesasignarHabitacionFormModal,
    BecariosAsignarPeriodoModal,
    BecariosFinalizarRetirarBecaModal,
    ModalBecarioAmbienteForm,
    ModalHistoriaAmbientesBecario,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      // Props
      itemHabitacion: {},
      itemPersona: {
        persona: {
          nombre_completo: '',
          pueblo_indigena: {
            nombre: '',
          },
          sexo_nombre: '',
        },
        escuela: {
          nombre: '',
        },
      },
      servicio: [],
      isBusy: false,
      mostrar: false,
      items: [],
      from: 1,
      to: 1,
      totalRows: 1,
      currentPage: 1,
      perPage: 500,
      pageOptions: [50, 100, 300, 500],
      sortBy: 'id',
      sortDesc: false,
      sortDirection: 'desc',
      filter: '',
      filterOn: [],
      selectedPersona: {},
      selected: [],

      becariosSelected: [],
      es_finalizar: false,
    }
  },

  methods: {
    actualizarTabla() {
      this.filter = null
      this.$refs.refTablaBecariosResidencia.refresh()
    },
    async myProvider(ctx) {
      await this.$http.get('/servicio/BECA-RESIDENCIA/buscar').then(res => {
        this.servicio = [res.data.id]
      })
      // eslint-disable-next-line no-param-reassign
      ctx.servicios_id = this.servicio

      const promise = store.dispatch('residencia/fetchBecarios', { queryParams: ctx })

      // Must return a promise that resolves to an array of items
      return promise.then(response => {
        const {
          data, total, from, to,
        } = response.data
        // Pluck the array of items off our axios response
        const items = data

        this.totalRows = total || 0
        // this.from = meta.pagination.count
        this.from = from || 0

        this.to = to || 0
        // Must return an array of items or an empty array if an error occurred
        return items || []
      })
    },
    asignarPeriodo() {
      this.$emit('asignarPeriodo', this.becariosSelected)
    },
    finalizarBeca() {
      this.$emit('finalizarRetirarBeca', { becariosSelected: this.becariosSelected, es_finalizar: true })
      this.es_finalizar = true
    },
    retirarBeca() {
      this.$emit('finalizarRetirarBeca', { becariosSelected: this.becariosSelected, es_finalizar: false })
      this.es_finalizar = false
    },
    selectAllRows() {
      this.$refs.refTablaBecariosResidencia.selectAllRows()
    },
    clearSelected() {
      this.$refs.refTablaBecariosResidencia.clearSelected()
    },
    verHabitaciones(item) {
      this.$emit('abrirModalHistoriaAmbientes', item)
    },
    showDesasignar(item) {
      this.itemPersona = item
      const habitaciones = item.becario_habitacion
      const [habitacion] = habitaciones.filter(i => i.estado === 1)
      this.itemHabitacion = habitacion
    },

    onRowSelected(items) {
      this.becariosSelected = items
      this.becariosSelected = this.becariosSelected.map((val => ({ becario_id: val.id, convocatoria_id: val.periodo_actual.convocatoria_id })))
    },

    asignarHabitacion(item) {
      let activos = []
      activos = item.becario_habitacion.filter(i => i.estado === 1)

      if (activos <= 0) {
        this.$emit('abrirModalAsignarHabitacion', item.id)
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: '¡Aviso!',
            icon: 'EditIcon',
            text: 'Ya tiene habitación',
            variant: 'warning',
          },
        })
      }
    },
    asignar(item) {
      const { usuario, ...rest } = item
      const { perfil } = usuario
      const { persona } = perfil
      this.editedItem = { ...rest, persona }

      this.$root.$emit('bv::show::modal', 'modal-aprobar-servicios', '#refModalAprobarServicios')
    },

    onSubmitmModalFinzalizarRetirar(data) {
      store.dispatch('residencia/actualizarPeriodoBeca', data).then(() => {
        this.actualizarTabla()
        this.$swal({
          icon: 'success',
          title: '¡Éxito!',
          text: this.es_finalizar ? 'La beca ha finalizado correctamente.' : 'Se retiro la beca correctamente.',
          customClass: {
            confirmButton: 'btn btn-success',
          },
        })
          .then(() => {
            this.$bvModal.hide('modal_asignar_periodo')
          })
      }).catch(() => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Error!',
            text: '',
            icon: 'AlertCircleIcon',
            variant: 'danger',
          },
        })
      })
    },
  },
  setup() {
    // const actualizarTabla2 = () => {

    //   $refs.refTablaBecariosResidencia.refresh()
    // }
    const RESIDENCIA_APP_STORE_MODULE_NAME = 'residencia'

    // Register module
    if (!store.hasModule(RESIDENCIA_APP_STORE_MODULE_NAME)) store.registerModule(RESIDENCIA_APP_STORE_MODULE_NAME, becarioStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(RESIDENCIA_APP_STORE_MODULE_NAME)) store.unregisterModule(RESIDENCIA_APP_STORE_MODULE_NAME)
    })

    const resolvetatusVariant = val => {
      if (val === 'activo') return 'success'
      if (val === 'inactivo') return 'danger'
      if (val === 'pendiente') return 'warning'
      return 'primary'
    }

    const { resolveColorServicioVariant } = useSaludUtilitarios()

    const fields = [
      { key: 'index', label: 'N°', sortable: false },
      { key: 'selected', label: '', sortable: false },
      { key: 'usuario', label: 'residente', sortable: false },
      // { key: 'convocatoria.nombre', label: 'conv.', sortable: false },
      // { key: 'modalidad.nombre', label: 'mod.', sortable: false },
      // { key: 'desdeTriaje', label: 'de triaje', sortable: false },
      // {
      //   key: 'atencion', label: 'interconsulta', sortable: false, formatter: 'serviciosInter',
      // },
      // { key: 'created_at', label: 'f. registro', sortable: false },
      {
        key: 'habitacion', label: 'habitación activo', class: 'text-center', sortable: false,
      },
      { key: 'fecha_inicio', label: 'F.inicio', sortable: false },
      // { key: 'fecha_fin', label: 'F.fin (programado)', sortable: false },
      { key: 'fecha_fin_salida', label: 'f.salida', sortable: false },
      {
        key: 'estado',
        sortable: true,
        // formatter: value => (value ? 'Activo' : 'Inactivo'),
      },
      { key: 'acciones' },
    ]

    const resolveUserServicioVariant = val => {
      if (val === 'FARMACIA') return 'primary'
      if (val === 'ODONTOLOGÍA') return 'warning'
      if (val === 'OFTAMOLOGÍA') return 'success'
      if (val === 'TÓPICO') return 'info'
      if (val === 'GINECO-OBSTÉTRICO') return 'danger'
      return 'primary'
    }

    return {
      // Filter
      avatarText,
      fields,
      resolvetatusVariant,
      resolveUserServicioVariant,
      resolveColorServicioVariant,
    }
  },
}
</script>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
