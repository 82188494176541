<template>
  <div>
    <b-modal
      id="modal_asignar_ambiente"
      v-model="dialog"
      size="xl"
      header-bg-variant="primary"
      :hide-footer="true"
    >
      <template
        #modal-header
      >
        <div class="text-white">
          <strong class="float-left">
            ASIGNAR AMBIENTE
          </strong>
        </div>

      </template>

      <!-- Alert: No item found -->
      <b-alert
        variant="danger"
        :show="residenteData === undefined"
      >
        <h4 class="alert-heading">
          Error al obtener los datos del usuario
        </h4>
        <div class="alert-body">
          No se encontró ningún usuario con este identificador de usuario. Consulte
          <b-link
            class="alert-link"
            :to="{ name: 'social-residentes-list' }"
          >
            la lista de usuarios
          </b-link>
          para ver si hay otros usuarios.
        </div>
      </b-alert>

      <template v-if="residenteData">
        <b-card
          border-variant="primary"
          no-body
        >
          <b-card-body>
            <b-row>
              <b-col
                lg="8"
              >
                <h2 class="mb-0">
                  RESIDENTE: {{ residenteData.persona.nombre_completo }}
                </h2>
                <h5 class="mb-0">
                  ESCUELA: {{ residenteData.escuela.nombre }}
                </h5>
                <h6 class="text-muted mb-0">
                  PUEBLO INDIGENA: {{ residenteData.persona.pueblo_indigena.nombre }}
                </h6>
                <h6 class="text-muted">
                  SEXO: {{ residenteData.persona.sexo_nombre }}
                </h6>
              </b-col>
              <b-col
                lg="2"
                offset-lg="2"
              >
                <!-- <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="relief-danger"
                  pill
                  block
                  size="sm"
                  @click="$router.push({ name: 'residencia-becarios-list' })"
                >
                  <feather-icon
                    icon="ArrowLeftCircleIcon"
                    class="mr-50"
                    size="16"
                  />
                  Regresar
                </b-button> -->
              </b-col>
            </b-row>

            <hr>

            <b-form @submit.prevent="onSubmit">
              <!-- Residentes -->
              <b-row>
                <b-col
                  sm="10"
                  md="10"
                  lg="10"
                >
                  <b-form-group
                    label="Albergue - Habitación - Tipo"
                    label-class="text-uppercase font-weight-bold"
                    label-size="sm"
                  >
                    <vue-autosuggest
                      ref="inputResidencia"
                      v-model="query"
                      :suggestions="suggestions"
                      :render-suggestion="renderSuggestion"
                      :get-suggestion-value="getSuggestionValue"
                      :input-props="{id:'autosuggest__input', placeholder:'', class: 'form-control'}"
                      @selected="setResidenciaSelect"
                      @input="fetchResults"
                      @focus="fetchResults"
                    />
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-alert
                    variant="danger"
                    show
                    class="text-center"
                  >
                    <h4 class="alert-heading">
                      Aforo
                    </h4>
                    <div class="alert-body">
                      <span class="font-weight-bold">{{ habitacion.aforo_subtotal }} / {{ habitacion.aforo_total }}</span>
                    </div>
                  </b-alert>
                </b-col>
              </b-row>
              <!-- info -->

              <!-- Fechas -->
              <b-row>
                <b-col md="4">
                  <b-form-group
                    label="bienes"
                    label-class="text-uppercase font-weight-bold"
                    label-size="sm"
                  >
                    <vue-autosuggest
                      ref="inputBienes"
                      v-model="queryBienes"
                      :suggestions="suggestionsBienes"
                      :render-suggestion="renderSuggestionBienes"
                      :get-suggestion-value="getSuggestionValueBienes"
                      :input-props="{id:'autosuggest__input', placeholder:'', class: 'form-control'}"
                      @selected="setResidenciaSelectBienes"
                      @input="fetchResultsBienes"
                      @focus="fetchResultsBienes"
                    />

                  </b-form-group>

                </b-col>
                <!-- Fecha ingreso -->
                <b-col md="3">
                  <b-form-group
                    label="Fecha ingreso"
                    label-class="text-uppercase font-weight-bold"
                    label-size="sm"
                  >
                    <flat-pickr
                      v-model="residenteHabitacionData.fecha_ingreso"
                      :config="config"
                      required
                    />
                  </b-form-group>
                </b-col>
                <!-- Fecha salida -->
                <b-col md="3">
                  <b-form-group
                    label="Fecha salida"
                    label-class="text-uppercase font-weight-bold"
                    label-size="sm"
                  >
                    <flat-pickr
                      v-model="residenteHabitacionData.fecha_salida"
                      :config="config"
                    />
                  </b-form-group>
                </b-col>
                <!-- Button Submit -->
                <b-col md="2">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="submit"
                    variant="success"
                    class="mr-1 mt-1"
                    size="sm"
                    block
                  >
                    <feather-icon
                      icon="SaveIcon"
                      size="16"
                    />
                    Grabar
                  </b-button>
                </b-col>
              </b-row>

              <b-row>
                <b-col md="4">
                  <b-table
                    class="mt-1"
                    :items="residenteHabitacionData.bienes"
                    :fields="fieldsBienes"
                    head-variant="dark"
                    responsive
                    hover
                    small
                  >
                    <template #cell(nombre)="row">
                      <span class="text-nowrap">
                        {{ row.item.nombre }}
                      </span>
                    </template>
                    <template #cell(actions)="row">
                      <b-button
                        variant="flat-danger"
                        class="btn-icon"
                        @click="deleteItemBien(row.index)"
                      >
                        <feather-icon
                          icon="Trash2Icon"
                          size="16"
                        />
                      </b-button>
                    </template>
                    <template #cell(cantidad)="data">
                      <b-form-input
                        v-model="data.item.cantidad"
                        type="number"
                        size="sm"
                      />
                    </template>

                  </b-table>
                </b-col>

                <b-col md="8" />
              </b-row>
            </b-form>
          </b-card-body>
        </b-card>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow, BCol, BForm, BFormGroup, BButton, BTable, BCard, BCardBody, BModal,
  // BCardHeader,
  BAlert, BFormInput,
  BLink,
// BMedia, BAvatar,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { avatarText } from '@core/utils/filter'
import {
  ref, watch, nextTick, computed, onUnmounted,
} from '@vue/composition-api'
import store from '@/store'
import flatPickr from 'vue-flatpickr-component'
import { Spanish } from 'flatpickr/dist/l10n/es'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueAutosuggest } from 'vue-autosuggest'
import becarioStoreModule from './becarioStoreModule'

export default {
  components: {
    VueAutosuggest,
    flatPickr,
    BAlert,
    // BDropdown,
    // BDropdownItem,
    BFormInput,
    BLink,
    BRow,
    BCol,
    BCard,
    BForm,
    BFormGroup,
    BTable,
    BCardBody,
    BButton,
    BModal,
  },
  directives: {
    Ripple,
  },
  // props: {
  //   persona: {
  //     type: Object,
  //     default() {
  //       return { }
  //     },
  //   },
  //   editedItem: {
  //     type: Object,
  //     default() {
  //       return { }
  //     },
  //   },
  // },
  data() {
    return {
      config: {
        // static: true,
        // wrap: true, // set wrap to true only when using 'input-group'
        altFormat: 'd/m/Y H:i',
        enableTime: true,
        altInput: true,
        dateFormat: 'Y-m-d H:i',
        locale: Spanish, // locale for this instance only
      },
      tipos: [],

      query: '',
      queryBienes: '',
      selected: null,
      selectedBienes: null,
      timeout: null,
      debounceMilliseconds: 500,
      suggestions: [],
      suggestionsBienes: [],
      modalidades: [],
      habitacion: {
        aforo_subtotal: 0,
        aforo_total: 0,
      },
      bienesSeleccionados: [
        { cantidad: 2, nombre: 'COLCHON' },
        { cantidad: 1, nombre: 'JUEGO  DE SABANAS' },
      ],
      bienes: [],
      residentes: [],
      fieldsBienes: [
        {
          key: 'actions', label: '*',
        },
        { key: 'cantidad', label: 'cant.' },
        { key: 'nombre', label: 'nombre' },
      ],
      fields: [
        { key: 'usuario', label: 'residente' },
        { key: 'pivot.fecha_ingreso', label: 'F. Ingreso' },
        { key: 'pivot.fecha_salida', label: 'F. Salida' },
        { key: 'actions', label: 'Acciones' },
      ],
    }
  },
  created() {
    this.$parent.$on('abrirModalAsignarHabitacion', this.abrirModalAsignarHabitacion)

    this.$http.get('becas/modalidades').then(response => { this.modalidades = response.data.data })
    this.$http.get('residencia/habitaciones/tipos').then(response => { this.tipos = response.data.data })
  },
  methods: {
    abrirModalAsignarHabitacion(id) {
      if (id) {
      // Obtener al becario
        store.dispatch('residencia/fetchBecario', { id })
          .then(response => {
            this.residenteHabitacionData.becario_id = response.data.id
            this.residenteHabitacionData.usuario_id = response.data.usuario_id
            this.residenteData = response.data

            this.$bvModal.show('modal_asignar_ambiente')
          })
          .catch(error => {
            if (error.response.status === 404) {
              this.residenteData = undefined
            }
          })
      }
    },
    deleteItemBien(index) {
      this.residenteHabitacionData.bienes.splice(index, 1)
    },
    deleteItem(index, item) {
      if (this.residenteHabitacionData.becario_id === item.pivot.becario_id && this.residenteHabitacionData.usuario_id === item.pivot.usuario_id) {
        this.$http.delete(`habitaciones/${item.pivot.id}/residentes-habitacion`)
          .then(() => {
            this.residentes.splice(index, 1)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Eliminado correctamente!',
                icon: 'EditIcon',
                variant: 'success',
              },
            })
          })
          .catch(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'No se puede eliminar!!',
                icon: 'EditIcon',
                variant: 'danger',
              },
            })
          })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'No se puede eliminar!',
            text: 'No se puede eliminar porque no le corresponde al residente!!',
            icon: 'EditIcon',
            variant: 'danger',
          },
        })
      }
    },
    // Habitacion
    setResidenciaSelect(obj) {
      this.habitacion = obj.item
      // hacer la peticion para saber que personas estan en la habitación
      this.$http.get(`habitaciones/${obj.item.id}/residentes`)
        .then(response => {
          this.residentes = response.data.data
        })

      this.residenteHabitacionData.habitacion_id = obj.item.id
    },
    setTipoHabitacionSelect(id) {
      this.residenteHabitacionData.tipo_id = id
    },
    renderSuggestion(suggestion) {
      const disponible = suggestion.item.aforo_total - suggestion.item.aforo_subtotal
      return (
        <div>
        {suggestion.item.residencia.nombre} -
        <span class="text-uppercase font-weight-bold h5"> Habitación: {suggestion.item.codigo} - {suggestion.item.tipo.nombre} </span> -
        Disponible: <span class="text-danger text-uppercase font-weight-bold h5"> {disponible}</span> -
        AFORO: <span class="text-danger text-uppercase font-weight-bold h5"> {suggestion.item.aforo_subtotal} / {suggestion.item.aforo_total}</span>
        </div>
      )
    },
    getSuggestionValue(suggestion) {
      return `${suggestion.item.residencia.nombre} - ${suggestion.item.codigo.toUpperCase()} - ${suggestion.item.tipo.nombre}`
    },
    fetchResults() {
      const { query } = this

      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        // const promise = this.$http.get(`habitaciones/?filter=${query}`)
        const promise = store.dispatch('residencia/fetchHabitaciones', query)
        // Must return a promise that resolves to an array of items
        return promise.then(response => {
          this.suggestions = []
          this.selected = null
          // Pluck the array of items off our axios response
          // const { items } = response.data
          // this.suggestions = response.data.data
          this.suggestions = [{ data: response.data.data }]
          // Must return an array of items or an empty array if an error occurred
          return this.suggestions || []
        })
      }, this.debounceMilliseconds)
    },
    // Bienes
    setResidenciaSelectBienes(obj) {
      const { item } = obj
      const { id, nombre } = item
      const dato = {
        bien_id: id, nombre, becario_id: this.residenteHabitacionData.becario_id, usuario_id: this.residenteHabitacionData.usuario_id, cantidad: 1,
      }

      const flag = this.residenteHabitacionData.bienes.some(i => i.bien_id === id)

      if (!flag) {
        // this.bienesSeleccionados.push(dato)
        this.residenteHabitacionData.bienes.push(dato)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Agregado correctamente!!',
            icon: 'EditIcon',
            variant: 'success',
          },
        })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Ya se agregó',
            icon: 'EditIcon',
            variant: 'danger',
          },
        })
      }
    },
    setTipoHabitacionSelectBienes(id) {
      this.residenteHabitacionData.tipo_id = id
    },
    renderSuggestionBienes(suggestion) {
      return (
        <div class="d-flex">
          {suggestion.item.nombre}
        </div>
      )
    },
    getSuggestionValueBienes(suggestion) {
      return `${suggestion.item.nombre.toUpperCase()}`
    },
    fetchResultsBienes() {
      const { queryBienes } = this

      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        // const promise = this.$http.get(`bienes/?filter=${queryBienes}`)
        const promise = store.dispatch('residencia/fetchBienes', queryBienes)
        // Must return a promise that resolves to an array of items
        return promise.then(response => {
          this.suggestionsBienes = []
          this.selectedBienes = null
          // Pluck the array of items off our axios response
          // const { items } = response.data
          // this.suggestions = response.data.data
          this.suggestionsBienes = [{ data: response.data.data }]
          // Must return an array of items or an empty array if an error occurred
          return this.suggestionsBienes || []
        })
      }, this.debounceMilliseconds)
    },
  },
  setup(props, { emit }) {
    const RESIDENCIA_APP_STORE_MODULE_NAME = 'residencia'

    // Register module
    if (!store.hasModule(RESIDENCIA_APP_STORE_MODULE_NAME)) store.registerModule(RESIDENCIA_APP_STORE_MODULE_NAME, becarioStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(RESIDENCIA_APP_STORE_MODULE_NAME)) store.unregisterModule(RESIDENCIA_APP_STORE_MODULE_NAME)
    })

    // const { editedItem } = toRefs(props)
    const editedIndex = ref(-1)
    const titleButton = ref('+')
    const dialog = ref(false)
    const toast = useToast()
    const residenteData = ref(null)
    const restData = ref(null)
    const form = {
      becario_id: null,
      usuario_id: null,
      habitacion_id: null,
      fecha_ingreso: null,
      fecha_salida: null,
      bienes: [],
    }

    const residenteHabitacionData = ref(JSON.parse(JSON.stringify(form)))

    const resetuserData = () => {
      residenteHabitacionData.value = JSON.parse(JSON.stringify(form))
      residenteHabitacionData.value.becario_id = residenteData.value.id
      residenteHabitacionData.value.usuario_id = residenteData.value.usuario_id
    }

    const close = () => {
      dialog.value = false
      nextTick(() => {
        resetuserData()
        editedIndex.value = -1
      })
    }

    const formTitle = computed(() => (editedIndex.value === -1 ? 'RESDIENTE: CHUMBE KAJEKUI, VIRGILIO' : `Editar Asignacion de residente N° ${editedIndex.value}`))

    watch(dialog, () => {
      // eslint-disable-next-line no-unused-expressions
      dialog.value || close()
    })

    const resolveUserServicioVariant = val => {
      if (val.codigo === '01-medicina') return 'primary'
      if (val.codigo === '02-odontologia') return 'secondary'
      if (val.codigo === '03-oftamologia') return 'success'
      if (val.codigo === '04-topico') return 'danger'
      if (val.codigo === '05-gineco-obstetrico') return 'obstetricia-color'
      if (val.codigo === '06-psicologia') return 'psicologia-color'
      if (val.codigo === '07-enfermeria') return 'info'
      return 'primary'
    }

    const onSubmit = () => {
      if (editedIndex.value > -1) {
      // vamos a editar

        store.dispatch('habitaciones/updatedHabitacion', residenteHabitacionData.value)
          .then(() => {
          // Clean form, Close Modal y Refresh Table
            toast({
              component: ToastificationContent,
              props: {
                title: '¡Actualización exitosa',
                icon: 'AlertTriangleIcon',
                variant: 'success',
              },
            })
            emit('actualizarTabla')
            dialog.value = false
            // emit('refresh-data-habitaciones-table')
          })
          .catch(() => {

          })
      } else {
        const arrayData = JSON.parse(JSON.stringify(residenteHabitacionData.value))
        // eslint-disable-next-line no-param-reassign
        arrayData.bienes.forEach(element => { delete element.nombre })
        store.dispatch('residencia/addResidenteHabitacion', arrayData)
          .then(() => {
          // Clean form, Close Modal y Refresh Table
            toast({
              component: ToastificationContent,
              props: {
                title: '¡Registro exitoso!',
                icon: 'AlertTriangleIcon',
                variant: 'success',
              },
            })
            resetuserData()
            dialog.value = false
            emit('actualizarTabla')
          })
          .catch(error => {
            if (error.response.status === 409) {
              toast({
                component: ToastificationContent,
                props: {
                  title: `${error.response.data.error}`,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            }
          })
      }
    }

    return {
      residenteHabitacionData,
      resetuserData,
      onSubmit,
      close,
      dialog,
      formTitle,
      titleButton,
      residenteData,
      resolveUserServicioVariant,
      avatarText,
      restData,
    }
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
  /* Number Type Input Box Scss for - Remove arrow on hover */
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: auto !important;
  }
</style>
