<template>
  <div>
    <b-modal
      id="modal_historia_ambientes_becario"
      title="Historial de ambientes asignado al becario"
      size="lg"
      :hide-footer="true"
      scrollable
    >
      <b-card class="mb-1 pl-2 border-left-primary border-right-primary border-top-primary">
        <b-row>
          <b-col
            md="8"
            sm="12"
          >
            <div>
              <h4 class="text-primary">
                HISTORIA DE AMBIENTES DEL BECARIO
              </h4>
              <ul>
                <li>
                  <strong>Becario:</strong> {{ becarioHistoria.persona.apellido_paterno || '' }} {{ becarioHistoria.persona.apellido_materno || '' }} {{ becarioHistoria.persona.nombres || '' }}
                </li>
                <li>
                  <strong>Etnia:</strong> {{ becarioHistoria.persona.pueblo_indigena.nombre || '' }}
                </li>
              </ul>
            </div>
          </b-col>

        </b-row>
      </b-card>
      <b-card
        class="mt-1 pl-2 border-left-primary border-right-primary border-bottom-primary"
      >
        <div v-if="historiaHabitacionesBecario.length > 0">
          <app-timeline>
            <app-timeline-item
              v-for="(habitacion, index) in historiaHabitacionesBecario"
              :key="index"
              variant="primary"
              class="hoverActividad pt-1"
              :style="index%2 === 0 ? 'background:#FAFAFA;' : ''"
            >
              <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                <h6 class="text-primary">
                  <b-badge
                    :variant="habitacion.estado ? 'success' : 'secondary'"
                  >
                    {{ habitacion.habitacion.codigo }}
                  </b-badge>
                </h6>
                <small class="mr-4">
                  <span>
                    <strong>Fecha ingreso:</strong> <span class="text-primary"> {{ habitacion.fecha_ingreso }}</span>
                    <br>
                    <strong>Fecha salida:</strong> <span class="text-primary"> {{ habitacion.fecha_salida }}</span>
                  </span>

                </small>

              </div>
              <ul>
                <li
                  v-for="(b,index2) in habitacion.bienes"
                  :key="index2"
                >
                  {{ b.nombre }} ({{ b.pivot.cantidad }})
                </li>
              </ul>

            </app-timeline-item>

          </app-timeline>
        </div>

      </b-card>

    </b-modal>
  </div>

</template>

<script>
import {
  BCard, BRow, BCol, BBadge,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
    BCard,

    BBadge,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  data() {
    return {

      historiaHabitacionesBecario: [],
      becarioHistoria: { persona: { pueblo_indigena: {} } },
    }
  },
  created() {
    this.$parent.$on('abrirModalHistoriaAmbientes', this.abrirModalHistoriaAmbientes)
  },
  methods: {

    abrirModalHistoriaAmbientes(item) {
      this.becarioHistoria = item
      this.historiaHabitacionesBecario = item.becario_habitacion
      this.$bvModal.show('modal_historia_ambientes_becario')
    },
    nuevaActividad() {
      this.$emit('nuevaActividadSeguimiento', this.itemDenuncia)
    },
    desasignarHabitación(actividad) {
      this.$emit('desasignarHabitaciónSeguimiento', actividad)
    },

  },

}
</script>

<style lang="scss" scoped>
.hoverActividad:hover {
  border-right: 2px solid green;
  border-left: 2px solid green;
}
</style>
