export default function saludUtilitarios() {
  const resolveColorServicioVariant = val => {
    if (val.codigo === '01-medicina') return 'primary'
    if (val.codigo === '02-odontologia') return 'secondary'
    if (val.codigo === '03-oftamologia') return 'success'
    if (val.codigo === '04-topico') return 'danger'
    if (val.codigo === '05-gineco-obstetrico') return 'obstetricia-color'
    if (val.codigo === '06-psicologia') return 'psicologia-color'
    if (val.codigo === '07-enfermeria') return 'info'
    return 'primary'
  }

  return {
    resolveColorServicioVariant,
  }
}
