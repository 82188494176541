import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchBecarios(ctx, { queryParams }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/becarios', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchBecario(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/becarios/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addResidenteHabitacion(ctx, residenteHabitacionData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/residencias/asignar-habitacion', residenteHabitacionData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // Habitaciones
    fetchHabitaciones(ctx, query) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/habitaciones?filter=${query}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // Bienes
    fetchBienes(ctx, query) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/bienes?filter=${query}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // Asignar periodo
    actualizarPeriodoBeca(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/servicio-social/beca/periodo-becario', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

  },
}
